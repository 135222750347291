export default function QuestClubLogo() {
  return (
    <div className="mb-7 flex flex-col items-center justify-center">
      <h1 className="text-3xl text-slate-400">Welcome to</h1>
      <h1 className="font-sans text-6xl font-extrabold text-slate-800 dark:text-slate-300">
        Quest Club
      </h1>
    </div>
  );
}
